import React, { useEffect } from 'react';
import { css, cx } from 'emotion';

const modalStyle = css`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%; /* Full width */
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
`;

const hideStyle = css``;

const showStyle = css``;

const modalContentStyle = css`
  position: fixed;
  padding: 1rem;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  //-webkit-animation-name: slideIn;
  //-webkit-animation-duration: 0.4s;
  //animation-name: slideIn;
  //animation-duration: 0.4s;
  max-height: 100%;
  display: block;
`;

const modalHeaderStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0 1.5rem 0;
`;
const titleStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: bold;
  color: #404042;
  letter-spacing: -0.03rem;
`;

export const Modal = props => {
  const { title, children, show, handleClose, customModalStyle } = props;

  useEffect(() => {
    window.onclick = function(event) {
      const modal = document.getElementById('myModal');
      if (event.target === modal) {
        handleClose();
      }
    };
  }, [handleClose]);

  return (
    <div
      id="myModal"
      className={show ? cx(modalStyle, showStyle) : cx(modalStyle, hideStyle)}
    >
      <div
        className={
          customModalStyle
            ? cx(modalContentStyle, customModalStyle)
            : modalContentStyle
        }
      >
        <div className={modalHeaderStyle}>
          <div className={titleStyle}>{title}</div>
          <i className="material-icons" onClick={handleClose}>
            close
          </i>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};
