import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import onClickOutside from 'react-onclickoutside';
import { css, cx } from 'emotion';
import { get } from 'lodash';
import { useFormikField } from '../../utils/use-formik-field';

const optionStyle = css`
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.375rem;
  color: #404042;
  cursor: pointer;
  position: relative;
  display: block;
  padding: 1rem 0.914rem;
`;
const selectedOptionStyle = css`
  //background-color: white;
`;

const optionContainerStyle = css`
  position: absolute;
  display: block;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  box-shadow: 0px 10px 10px rgba(64, 64, 66, 0.24);
  border-top: 0;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  max-height: 25rem;
  overflow-y: auto;
`;
const triggerContainerStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  color: #aeaeae;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const optionContainerStyleOpen = css`
  opacity: 1;
  visibility: visible;
  pointer-events: all;
`;
const selectContainerStyle = css`
  position: relative;
  min-width: 100px;
  user-select: none;
  width: 100%;
  border: 2px solid #e5e5e5;
  height: 3.2rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`;
const selectContainerOpenStyle = css`
  border-color: #25bfdb;
  box-shadow: 0px 10px 10px rgba(64, 64, 66, 0.24);
`;
const optionDividerStyle = css`
  width: 100%;
  position: absolute;
  border: 1px solid #e5e5e5;
`;
const arrowStyle = css`
  position: relative;
  height: 7.5px;
  width: 10px;
  ::before,
  ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 0.12rem;
    height: 100%;
    transition: all 0.25s;
  }
  ::before {
    left: -2.5px;
    transform: rotate(-45deg);
    background-color: #404042;
  }
  ::after {
    left: 2.5px;
    transform: rotate(45deg);
    background-color: #404042;
  }
`;
const openArrowStyle = css`
  ::before {
    left: -2.5px;
    transform: rotate(45deg);
    background: #25bfdb;
  }
  ::after {
    left: 2.5px;
    transform: rotate(-45deg);
    background: #25bfdb;
  }
`;

const focusLabel = css`
  color: #25bfdb;
  background-color: transparent;
  transition: all 0.25s ease-out;
  position: absolute;
  left: 0;
  top: -0.625rem;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
`;

const hasValueStyle = css`
  color: #aeaeae;
`

const placeholderStyle = css`
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 0;
  top: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  pointer-events: none;
`;

const labelContainer = css`
  position: relative;
  flex-grow: 1;
  height: 1.375rem;
`;

const optionTextStyle = css`
  font-size: 16px;
  position: relative;
  left: 0;
  bottom: -0.5rem;
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

const wrapperStyle = css`
  margin: 0 0 1rem 0;
`;

const InnerSelectField = props => {
  const {
    formikName,
    options = [],
    open,
    setOpen,
    placeholder,
    customOptionsStyle,
    size,
  } = props;

  const getSizeStyle = () => {
    switch (size) {
      case 's':
        return '17%';
      case 'm':
        return '49%';
      case 'l':
        return '100%';
      default:
        return '100%';
    }
  };

  const [field, _] = useField({ name: formikName });
  const { isInErrorState, errorMessage, touched } = useFormikField(formikName);

  const { setFieldValue } = useFormikContext();
  const renderOption = option => {
    const isSelected = field.value === option.code;
    return (
      <div>
        <span
          key={option.code}
          onClick={() => {
            setFieldValue(formikName, {
              code: option.code,
              description: option.value,
            });
            setOpen(false);
          }}
          className={cx(optionStyle, isSelected && selectedOptionStyle)}
          data-value={option.code}
        >
          {option.value}
        </span>
        <span className={optionDividerStyle} />
      </div>
    );
  };
  const option = options.find(o => o.code === get(field.value, 'code'));
  return (
    <div className={wrapperStyle}>
      <div
        onClick={() => setOpen(!open)}
        className={cx(selectContainerStyle, open && selectContainerOpenStyle)}
        style={{ width: getSizeStyle() }}
      >
        <div>
          <div className={triggerContainerStyle}>
            <div className={labelContainer}>
              {option && (
                <span className={optionTextStyle}>{option.value}</span>
              )}
              <label className={open ? focusLabel : option ? cx(focusLabel, hasValueStyle) : placeholderStyle}>
                {placeholder}
              </label>
            </div>
            <div className={cx(arrowStyle, open && openArrowStyle)} />
          </div>
          <div
            className={cx(
              optionContainerStyle,
              open && optionContainerStyleOpen,
              customOptionsStyle && customOptionsStyle
            )}
          >
            {options.map(renderOption)}
          </div>
        </div>
      </div>
      {isInErrorState && touched && (
        <div className={errorStyle}>{errorMessage.code}</div>
      )}
    </div>
  );
};

class SelectWrapper extends React.Component {
  state = {
    open: false,
  };

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <InnerSelectField
        open={this.state.open}
        setOpen={value => this.setState({ open: value })}
        {...this.props}
      />
    );
  }
}
export const SelectField = onClickOutside(SelectWrapper);
