import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { useFormikField } from '../../utils/use-formik-field';
import checkUrl from './assets/check.svg';
import {levelOfCoverData} from "../../contants/level-of-cover";

const containerStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  max-width: 912px;
`;

const optionStyle = css`
  text-align: center;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  position: relative;
  padding: 4em 2em 1em 2em;
  width: calc(33.33% - 20px);
  filter: grayscale(1);
  &:hover {
    filter: grayscale(0.3);
  }

  @media only screen and (max-width: 1024px) {
    display: flex;
    max-width: 100%;
    padding: 1em 16px 1em 52px;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 2.5rem;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      left: 16px;
      height: 20px;
      width: 20px;
      background-color: white;
      border: 6px solid #bebebe;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
`;

const selectedOptionsStyle = css`
  border: 2px solid #25bfdb;
  background: #effdff;
  filter: grayscale(0);

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #25bfdb;
    background-image: url(${checkUrl});
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: center;
    top: 16px;
    right: 16px;
    border-radius: 50%;
    border-color: transparent;
  }

  @media only screen and (max-width: 1024px) {
    &::after {
      top: 20px;
      left: 16px;
      background-size: 12px 12px;
    }
  }
`;

const textStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5em;
  text-align: center;
  text-transform: uppercase;
  color: #404042;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 1024px) {
    margin: auto 0;
    text-align: left;
  }
`;

const dscStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5em;
  text-align: center;
  color: #404042;
  @media only screen and (max-width: 1024px) {
    text-align: left;
  }
`;

const imgStyle = css`
  height: 5rem;
  @media only screen and (max-width: 1024px) {
    height: 2em;
    order: 2;
    margin-left: auto;
  }
`;

const normalTextStyle = css`
  font-weight: normal;
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  margin-top: 0.5rem;
`;

const additionalStyle = css`
  margin-top: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  color: #a6a4a4;
  text-align: left;
`;

const subtitleStyle = css`
  font-weight: bold;
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: #25bfdb;
  text-transform: uppercase;
`

const rowStyle = css`
  margin-bottom: 5px;
`;

export const PremiumSelectComponent = props => {
  const { options, onChange, id, value, error } = props;
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div id={id} className={containerStyle}>
      {options.map((o, i) => (
        <div
          id={o.code}
          key={i}
          onClick={() => {
            onChange(o.value);
          }}
          className={
            o.value === value
              ? cx(optionStyle, selectedOptionsStyle)
              : optionStyle
          }
        >
          <img src={o.imgUrl} alt={o.code} className={imgStyle} />
          <div>
            <div className={textStyle}>{o.value}</div>
            <div className={dscStyle}>
              {o.dsc}
              <span className={normalTextStyle}> p/m</span>
            </div>
            <div onClick={() => setShowDetails(!showDetails)} className={cx(additionalStyle,subtitleStyle)}>
              {showDetails ? "Show less...":"What's covered?"}
            </div>
            {showDetails && <>
                <div className={additionalStyle}>
                  <p className={subtitleStyle}>CORE COVERS</p>
                  {o.core.map((a, id) => (
                    <p id={id} className={rowStyle}>
                      {a}
                    </p>
                  ))}
                </div>
                <div className={additionalStyle}>
                  <p className={subtitleStyle}>ADDITIONAL COVERS</p>
                  {o.additional.map((a, id) => (
                    <p id={id} className={rowStyle}>
                      {a}
                    </p>
                  ))}
                </div>
              </>}
          </div>
        </div>
      ))}
      {error && <p className={errorStyle}>{error}</p>}
    </div>
  );
};

export const PremiumSelect = props => {
  const { name, id } = props;
  const { value, setValue, errorMessage, touched } = useFormikField(name);

  return (
    <PremiumSelectComponent
      name={name}
      id={id}
      value={value || ''}
      options={levelOfCoverData}
      onChange={setValue}
      touched={touched}
      error={errorMessage}
    />
  );
};
