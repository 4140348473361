import * as Yup from 'yup';
import moment from "moment";
import './customYupValidations';

export const validationSchema = [
  Yup.object().shape({
    levelOfCover: Yup.string().required('You have to pick a level of cover'),
  }),
  Yup.object().shape({
    EffectivePeriodStartDate: Yup.string().required(
      'Cover start date is required field'
    ),
  }),
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({
    title: Yup.object().shape({
      code: Yup.string().required('Title is required field'),
      description: Yup.string().required('Title is required field'),
    }),
    firstName: Yup.string().required('First name is required field'),
    lastName: Yup.string().required('Last name is required field'),
    email: Yup.string().email('Email must be a valid email').required('Email is required field'),
    telephone: Yup.string().required('Phone is required field'),
    dateOfBirth: Yup.string().isDate('Date of Birth must have a valid date value').required('Date of birth is required field')
      .test('isAdult', 'Unfortunately we cannot provide a quote as you are under the age of 18', value => moment().diff(moment(value, 'DD/MM/YYYY', true), 'years') >= 18),
    address: Yup.object().shape({
      addressLine1: Yup.string().required('Address Line 1 is required field'),
      city: Yup.string().required('City is required field'),
      postcode: Yup.string().required('Postcode is required field'),
    }),
  }),
  Yup.object().shape({
    namedParty: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().required('First name is required field'),
          lastName: Yup.string().required('Last name is required field'),
          title: Yup.object().shape({
            code: Yup.string().required('Title is required field'),
            description: Yup.string().required('Title is required field'),
          }),
          dob: Yup.string().isDate('Date of Birth must have a valid date value').required('Date of birth is required field'),
        })
      )
      .when(
        ['howManyAdults', 'howManyChildren'],
        (howManyAdults, howManyChildren, schema) =>
          schema.min(howManyChildren + howManyAdults)
      )
      .required('You have to add at least one additional member'),
  }),
  Yup.object().shape({
    accountName: Yup.string().required('Account holder name is required field'),
    accountNumber: Yup.string().required('Account number is required field'),
    sortCode: Yup.string().required('Sort code is required field'),
    collectionDate: Yup.object().shape({
      code: Yup.string().required('Payment details is required field'),
      description: Yup.string().required('Payment details is required field'),
    }),
  }),
  Yup.object().shape({
    assumptions: Yup.boolean()
      .required('Assumptions is required field')
      .oneOf([true], 'You have to agree with the assumptions to continue'),
    declarationsBox: Yup.boolean()
      .required('Declaration is required field')
      .oneOf([true], 'You have to agree with the declarations to continue'),
  }),
];
