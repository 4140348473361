import React from 'react';
import { css } from 'emotion';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 2.5rem 3rem 2.5rem 3rem;
  height: 100%;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }
`;

export const Container = props => (
  <div className={containerStyle}>{props.children}</div>
);
