import React from 'react';
import Button from 'reactstrap/es/Button';
import { css, cx } from 'emotion';
import arrowRight from './assets/arrowRight.svg';
import arrowLeft from './assets/arrowLeft.svg';

const buttonStyle = css`
   {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.5em;
    text-transform: uppercase;
    padding: 1rem 1.5rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

const primaryButtonStyle = css`
   {
    background-color: #25bfdb;
    color: white;
    //box-shadow: 0px 18px 15px -15px rgba(37, 191, 219, 0.5);
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

const labelPrimary = css`
  margin-right: 3rem;
  display: inline-block;
`;

const labelSecondary = css`
  margin-left: 3rem;
  display: inline-block;
`;

const secondaryButtonStyle = css`
   {
    color: #25bfdb;
    border: none;
    background: transparent;
    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }
`;

export const CustomButton = props => {
  const { handleClick, secondary, label, id } = props;

  return (
    <Button
      onClick={handleClick}
      id={id}
      className={
        secondary
          ? cx(buttonStyle, secondaryButtonStyle)
          : cx(buttonStyle, primaryButtonStyle)
      }
    >
      {secondary && <img src={arrowLeft} alt="arrow-left" />}
      <span className={secondary ? labelSecondary : labelPrimary}>{label}</span>
      {!secondary && <img src={arrowRight} alt="arrow-right" />}
    </Button>
  );
};
