import { observable } from 'mobx';
import { get } from 'lodash';

const STRIPE_PRICES_PATH = 'underwrittenOptions[0].paymentCollectionOptions[0]';
const RATE_ERROR_PATH =
  'underwrittenOptions[0].underwrittenReferrals[0].referralText';

export class QuoteStore {
  @observable
  quoteRef = undefined;

  @observable
  policyId = undefined;

  @observable
  underwrittenPrice = {
    totalAmount: undefined,
  };

  @observable
  rateError = undefined;

  @observable
  apiError = undefined;

  @observable
  isSubmitting = false;

  @observable
  rateResponse = undefined;

  @observable
  savedQuoteId = null;

  @observable
  quoteIsSaved = false;

  saveQuoteData = quote => {
    this.rateResponse = quote;
    const { underwritingOutcome } = quote;
    this.quoteRef = quote.quoteRef;
    const quoteStripePrice = get(underwritingOutcome, STRIPE_PRICES_PATH);

    this.underwrittenPrice = {
      totalAmount: get(quoteStripePrice, 'totalAmountPayable.amount'),
    };
    this.rateError = get(underwritingOutcome, RATE_ERROR_PATH);
    this.isSubmitting = false;
  };
}

export const quoteStore = new QuoteStore();
