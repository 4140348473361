import { observable } from 'mobx';

export class StepStore {
  @observable
  step = 1;

  nextStep = () => this.step++;

  previousStep = () => this.step--;

  skipNSteps = n => (this.step = this.step + n);

  returnNSteps = n => (this.step = this.step - n);
}

export const stepStore = new StepStore();
