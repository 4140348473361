import React from 'react';
import { css } from 'emotion';
import logo from '../../assets/secundary-logo.png';

const footerStyle = css`
  background: #4fbfdb;
  font-size: 0.5573rem;
  color: white;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const contentStyle = css`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 90%;
    margin-top: 1rem;
  }
`;

const imageStyle = css`
  height: 6.5rem;
  margin-right: 2rem;
`;

const additionStyle = css`
  margin-top: 1rem;
`;

export const Footer = () => (
  <fotter className={footerStyle}>
    <img src={logo} alt="success" className={imageStyle} />
    <div className={contentStyle}>
      <p>
        © Insync Insurance Solutions Ltd 2020 All rights reserved.
        YourDentalCover is trading style of Insync Insurance Solutions Ltd which
        is authorised and regulated by the FInancial Conduct Authority. where
        our reference number is 766691. Our registered office is Midland House,
        2 Poole Road, Bournemouth, Dorset BH2 5QY and we are registered in
        England under company number 08810662. Should you have cause to
        complain, and you are not satisfied with our response to your complaint,
        you may be able to refer it to the Financial Ombudsman Service, which
        can be contacted as follows: The Financial Ombudsman Service Exchange
        Tower, London, E14 9SR | Tel: 0800 023 4567 or 0300 123 9 123 |
        www.financial-ombudsman.org.uk
      </p>
      <p className={additionStyle}>*or equivalent brush</p>
    </div>
  </fotter>
);
