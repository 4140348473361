import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { QuoteDetails } from '../../components/QuoteDetails';
import { SelectField } from '../../components/SelectField';
import { InputField } from '../../components/InputField';
import { lookupStore } from '../../../store/lookup-store';
import { NumberFormatField } from '../../components/NumberFormatField';

const contentContainer = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const inputContainer = css`
  flex: 1;
  margin-right: 5rem;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
`;

const labelStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #404042;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
`;

const tooltipHolder = css`
  margin-left: 0.5rem;
  transform: scale(80%);
  width: 20px;
  line-height: 20px;
  font-size: 0.75rem;
  color: white;
  background: #404042;
  border-radius: 50%;
  opacity: 0.7;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  &:hover .tooltip {
    display: block;
  }
  &:hover {
    opacity: 1;
  }
  &:hover::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: #404042;
    position: absolute;
    bottom: 100%;
    transform: rotate(45deg);
  }
`;

const topIcon = css`
  margin-bottom: 40px;
`;

const tooltip = css`
  position: absolute;
  background: #404042;
  border-radius: 5px;
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.5rem;
  bottom: calc(100% + 0.25rem);
  right: 0;
  width: 100%;
  display: none;
  text-transform: none;
`;

const tooltipContainer = css`
  font-family: 'Open Sans', sans-serif;
  color: #404042;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  align-items: center;
`;

const readMoreStyle = css`
  font-family: 'Open Sans', sans-serif;
  color: #25bfdb;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  &:hover {
    color: #86e5f7;
  }
`;

const readLessStyle = css`
  font-family: 'Open Sans', sans-serif;
  color: #25bfdb;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0.5rem 0 1rem 0;
  cursor: pointer;
  &:hover {
    color: #86e5f7;
  }
`;

const detailsStyle = css`
  font-family: 'Open Sans', sans-serif;
  color: #404042;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export const BankDetails = props => {
  const { handleNextStep, formik } = props;
  const [collapsed, setCollapsed] = useState(true);

  const goToPreviousStep = () => {
    if (formik.values.howManyAdults || formik.values.howManyChildren) {
      stepStore.previousStep();
    } else {
      stepStore.returnNSteps(2);
    }
  };

  return (
    <Container>
      <Title title="Payment details for monthly Direct Debit" />
      <div className={contentContainer}>
        <div className={inputContainer}>
          <p className={labelStyle}>Account holder details</p>
          <InputField
            name="accountName"
            id="accountName"
            placeholder="Name of account holder(s)"
            size="l"
          />
          <p className={labelStyle}>Account details</p>
          <NumberFormatField
            name="accountNumber"
            id="accountNumber"
            type="number"
            label="Value"
            min={1}
            thousandSeparator
            placeholder="Account number"
            numberFormatPlaceholder="XXXXXXXX"
            format="########"
            mask="X"
            size="l"
          />
          <p className={tooltipContainer}>
            <NumberFormatField
              name="sortCode"
              id="sortCode"
              type="number"
              label="Value"
              min={1}
              thousandSeparator
              placeholder="Sort code"
              numberFormatPlaceholder="XX-XX-XX"
              format="##-##-##"
              mask="X"
              size="m"
            />
            <span className={cx(tooltipHolder, topIcon)}>
              ?
              <div className={cx(tooltip, 'tooltip')}>
                You can find this on your bank card or cheque book
              </div>
            </span>
          </p>
          <p className={labelStyle}>
            Payment details
            <span className={tooltipHolder}>
              ?
              <div className={cx(tooltip, 'tooltip')}>
                Please select the preferred collection date for your monthly
                payments
              </div>
            </span>
          </p>
          <SelectField
            formikName="collectionDate"
            placeholder="Preferred collection date"
            options={lookupStore.paymentDetails || []}
          />
          <div className={readMoreStyle} onClick={() => setCollapsed(false)}>
            Click here to Read Direct Debit Guarantee...
          </div>
          {!collapsed && (
            <div>
              <p className={detailsStyle}>
                This Guarantee is offered by all banks and building societies
                that accept instructions to pay Direct Debits.
              </p>
              <p className={detailsStyle}>
                If there are any changes to the amount, date or frequency of
                your Direct Debit Unum Dental will notify you 10 working days in
                advance of your account being debited or as otherwise agreed. If
                you request Unum Dental to collect a payment, confirmation of
                the amount and date will be given to you at the time of the
                request.
              </p>
              <p className={detailsStyle}>
                If an error is made in the payment of your Direct Debit, by Unum
                Dental or your bank or building society you are entitled to a
                full and immediate refund of the amount paid from your bank or
                building society
              </p>
              <p className={detailsStyle}>
                If you receive a refund you are not entitled to, you must pay it
                back when Unum Dental asks you to.
              </p>
              <p className={detailsStyle}>
                You can cancel a Direct Debit at any time by simply contacting
                your bank or building society. Written confirmation may be
                required. Please also notify us.
              </p>
              <p className={readLessStyle} onClick={() => setCollapsed(true)}>
                Read less...
              </p>
            </div>
          )}
        </div>
        <QuoteDetails formik={formik} />
      </div>
      <ButtonsContainer quoteScreen>
        <CustomButton
          handleClick={goToPreviousStep}
          label="previous step"
          secondary
        />
        <CustomButton handleClick={handleNextStep} label="next step" />
      </ButtonsContainer>
    </Container>
  );
};
