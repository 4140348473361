import React, { useState } from 'react';
import { css } from 'emotion';
import { connect } from 'formik';
import { get } from 'lodash';
import { observer } from 'mobx-react';

import { InputField } from './InputField';
import { postCodeStore } from '../../store/post-code-store';
import { useFirebase } from '../Firebase';
import { Modal } from './Modal';
import { CustomButton } from './CustomButton';

import iconDown from './assets/arrowDown.svg';
import iconUp from './assets/arrowUp.svg';
import { Loader } from './Loader';

const containerStyle = css`
  @media (max-width: 800px) {
    border: none !important;
  }
`;

const spinnerContainer = css`
  margin: 3rem 0 3rem 0;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const customModalStyle = css`
  height: 100%;
  max-height: 100%;
  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 44.375rem;
    height: 100%;
    position: static;
    margin: 0 auto;
  }
`;

const scrollableContainer = css`
  max-height: calc(100vh - 32rem);
  overflow-y: auto;
  margin: 0 -1rem;
`;

const bigScrollableContainer = css`
  max-height: calc(100vh - 19.5rem);
  overflow-y: auto;
  margin: 0 -1rem;
`;

const fixedButton = css`
  position: fixed;
  bottom: 2rem;
  display: flex;
  right: 1rem;
  left: 1rem;
  @media only screen and (min-width: 768px) {
    position: static;
    align-self: flex-end;
    margin-top: 1rem;
  }
`;

const dividerStyle = css`
  height: 2px;
  background-color: #25bfdb;
  margin: 0 -1rem;
`;

const groupItem = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  border-bottom: 1px solid #e5e5e5;
  line-height: 1.375rem;
  color: #404042;
  padding: 1rem;
`;

const modalContainer = css`
  display: flex;
  flex-direction: row;
`;

const inputContainer = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const iconContainer = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const iconStyle = css`
  position: relative;
  padding: 1.3rem 0.5rem;
`;

function summaryAddress(values) {
  if (values.address) {
    const { addressLine1, addressLine2, city } = values.address;
    return [addressLine1, addressLine2, city]
      .filter(x => x)
      .join(' ')
      .trim();
  }
}

export const AddressInfoFields = connect(
  observer(props => {
    const firebase = useFirebase();
    const [showModal, setShowModal] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const { formik } = props;

    const fixedValue = summaryAddress(formik.values);

    const handleFindAddress = async (event, postcode) => {
      event.preventDefault();
      setShowModal(true);
      postCodeStore.searchAddressInProgress = true;
      if (!postcode) {
        return;
      }
      const results = await firebase.doGetAddressAutocomplete(postcode);
      if (results) {
        postCodeStore.postcodeAutoComplete = results;
      }
      postCodeStore.searchAddressInProgress = false;
    };

    const handleAddressSelect = async (event, itemId) => {
      event.preventDefault();
      postCodeStore.searchAddressInProgress = true;
      try {
        const {
          line1,
          line2,
          city: detailsCity,
          postalCode
        } = await firebase.doGetAddressDetails(itemId);

        formik.setFieldValue('address.addressLine1', line1);
        formik.setFieldValue('address.addressLine2', line2);
        formik.setFieldValue('address.city', detailsCity);
        formik.setFieldValue('address.postcode', postalCode);

        postCodeStore.postcodeAutoComplete = [];
        postCodeStore.searchAddressInProgress = false;
        setCollapsed(false);
      } catch (e) {
        postCodeStore.searchAddressInProgress = false;
      }
    };
    const { postcodeAutoComplete, searchAddressInProgress } = postCodeStore;

    return (
      <div className={containerStyle}>
        {fixedValue && (
          <InputField
            placeholder="Address"
            id="address1"
            name="addressSummary"
            type="text"
            fixedValue={fixedValue}
            onAddOnClick={() => setShowModal(true)}
            addOnLabel="Edit"
          />
        )}
        {!fixedValue && (
          <InputField
            name="address.postcode"
            id="postcode"
            type="text"
            placeholder="Postcode*"
            onAddOnClick={handleFindAddress}
            addOnLabel="Find Address"
          />
        )}
        {showModal && (
          <Modal
            handleClose={() => setShowModal(false)}
            show={showModal}
            title="Find Address"
            customModalStyle={customModalStyle}
          >
            <InputField
              name="address.postcode"
              id="postcodeModal"
              type="text"
              placeholder="Postcode*"
            />
            <div className={modalContainer}>
              {!collapsed && (
                <div className={inputContainer}>
                  <InputField
                    placeholder="Address Line 1"
                    name="address.addressLine1"
                    id="addressLine1"
                    type="text"
                  />
                  <InputField
                    placeholder="Address Line 2"
                    name="address.addressLine2"
                    id="addressLine2"
                    type="text"
                  />
                  <InputField
                    placeholder="City"
                    name="address.city"
                    id="city"
                    type="text"
                  />
                </div>
              )}
              {collapsed && (
                <div
                  className={inputContainer}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <InputField
                    placeholder="Address"
                    id="address"
                    name="addressSummary"
                    type="text"
                    fixedValue={fixedValue}
                  />
                </div>
              )}
              {collapsed && (
                <div className={iconContainer}>
                  <img
                    src={iconDown}
                    alt="down"
                    className={iconStyle}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                </div>
              )}
              {!collapsed && (
                <div className={iconContainer}>
                  <img
                    src={iconUp}
                    alt="up"
                    className={iconStyle}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                </div>
              )}
            </div>
            <div>
              {searchAddressInProgress && (
                <div className={spinnerContainer}>
                  <Loader message="Searching for addresses..." />
                </div>
              )}
              {postcodeAutoComplete && !searchAddressInProgress && (
                <div>
                  <div className={dividerStyle} />
                  <div
                    className={
                      collapsed ? bigScrollableContainer : scrollableContainer
                    }
                  >
                    {postcodeAutoComplete.map(({ searchResult, itemId }) => (
                      <div
                        key={itemId}
                        onClick={e => handleAddressSelect(e, itemId)}
                        className={groupItem}
                      >
                        {searchResult}
                      </div>
                    ))}
                  </div>
                  <div className={dividerStyle} />
                </div>
              )}
            </div>
            <div className={fixedButton}>
              <CustomButton
                handleClick={() => setShowModal(false)}
                label="Confirm"
              />
            </div>
          </Modal>
        )}
      </div>
    );
  })
);
