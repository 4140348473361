import React from 'react';
import { InputGroup } from 'reactstrap';
import { css, cx } from 'emotion';

import NumberFormat from 'react-number-format';
import { useFormikField } from '../../utils/use-formik-field';

const inputContainerStyle = css`
  margin: 0 0 1rem 0;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

const disabledStyle = css`
  border-color: #b9b9b9 !important;
  border-radius: 4px;
  background-color: #b9b9b9 !important;
  color: white !important;
`;

const customInputStyle = css`
  width: 100%;
  padding: 0.7rem 1rem;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  outline: none;
  color: #929090;
  left: 0;
  top: 0;
  &:focus {
    font-size: 16px;
    top: 1.1rem;
    padding: 0.1rem 1rem 0.3rem 1rem;
    transition: all 0.25s ease-out;
    + label {
      color: #25bfdb;
      background-color: transparent;
      transition: all 0.25s ease-out;
      transform: translate(0, -1.2rem);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
    }
    ~ .borderDiv {
      box-shadow: 0 0 0 2px #25bfdb;
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const stretchedContainerStyle = css`
  display: flex;
  align-items: stretch;
`;

const labelStyle = css`
  width: calc(100% - 1.8rem);
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  color: #aeaeae;
  pointer-events: none;
`;

const customInputContainer = css`
  position: relative;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  flex: 1;
  height: 3.2rem;
`;

const hasValueState = css`
  label {
    color: #aeaeae;
    background-color: transparent;
    transition: all 0.25s ease-out;
    transform: translate(0, -1.2rem);
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  input {
    font-size: 16px;
    top: 1.1rem;
    padding: 0.1rem 1rem 0.3rem 1rem;
    transition: all 0.25s ease-out;
  }
`;

const borderStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 2px;
`;

export const NumberFormatComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    error,
    placeholder,
    onBlur,
    touched,
    disabled,
    min,
    thousandSeparator,
    size,
    numberFormatPlaceholder,
    format,
    mask,
    onFocus,
    focused,
  } = props;

  const handleChange = e => {
    onChange(e);
  };

  const getSizeStyle = () => {
    switch (size) {
      case 's':
        return '20%';
      case 'm':
        return '49%';
      case 'l':
        return '100%';
      default:
        return '100%';
    }
  };

  return (
    <div className={inputContainerStyle} style={{ width: getSizeStyle() }}>
      <InputGroup className={stretchedContainerStyle}>
        <div className={cx(customInputContainer, value && hasValueState)}>
          <NumberFormat
            name={name}
            id={id}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            min={min}
            placeholder={numberFormatPlaceholder}
            format={format}
            mask={mask}
            thousandSeparator={thousandSeparator}
            className={disabled ? disabledStyle : customInputStyle}
          />
          <label className={labelStyle}>{placeholder}</label>
          <div className={cx(borderStyle, 'borderDiv')} />
        </div>
      </InputGroup>
      {error && touched && !focused && <div className={errorStyle}>{error}</div>}
    </div>
  );
};

export const NumberFormatField = props => {
  const {
    label,
    name,
    id,
    placeholder,
    disabled,
    min,
    thousandSeparator,
    size,
    numberFormatPlaceholder,
    format,
    mask,
  } = props;

  const {
    value,
    onBlur,
    setValue,
    isInErrorState,
    errorMessage,
    touched,
    onFocus,
    focused,
  } = useFormikField(name);

  return (
    <NumberFormatComponent
      name={name}
      id={id}
      label={label}
      value={value || ''}
      isInErrorState={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      focused={focused}
      touched={touched}
      disabled={disabled}
      min={min}
      size={size}
      thousandSeparator={thousandSeparator}
      numberFormatPlaceholder={numberFormatPlaceholder}
      format={format}
      mask={mask}
    />
  );
};
