import React from 'react';
import { css } from 'emotion';

const titleStyle = css`
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.1em;
  color: #404042;
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export const Title = props => {
  const { title } = props;
  return <div className={titleStyle}>{title}</div>;
};
