import React from 'react';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { PremiumSelect } from '../../components/PremiumSelect';
import { CustomButton } from '../../components/CustomButton';
import { ButtonsContainer } from '../../components/ButtonsContainer';

export const ChoosePremium = props => {
  const { handleNextStep } = props;
  return (
    <Container>
      <Title title="Please select your level of cover" />
      <PremiumSelect name="levelOfCover" id="levelOfCover" />
      <ButtonsContainer>
        <CustomButton handleClick={handleNextStep} label="next step" />
      </ButtonsContainer>
    </Container>
  );
};
