import React from 'react';
import { css } from 'emotion';
import { times } from 'lodash';
import { stepStore } from '../../store/step-store';
import checkUrl from './assets/check.svg';

const progressBarStyle = css`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 46rem;
  margin: 0 auto;
`;

const textStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.5em;
  text-align: center;
  text-transform: uppercase;
  color: #a3a3a3;
  margin-bottom: 0.375rem;
`;

const finishedStep = css`
  background: #25bfdb;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  border: 2.5px solid transparent;
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: #25bfdb;
    background-image: url(${checkUrl});
    background-size: 13px 13px;
    background-repeat: no-repeat;
    background-position: center;
    top: 3.5px;
    right: 3.5px;
    border-radius: 50%;
  }
`;

const currentStep = css`
  width: 29px;
  height: 29px;
  background-color: white;
  border-radius: 50%;
  box-sizing: border-box;
  border: 9px solid #25bfdb;
`;

const pendingStep = css`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e5e5e5;
  box-sizing: border-box;
  border: 2.5px solid transparent;
`;

const defaultLine = css`
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  background-color: #e5e5e5;
  z-index: -2;
`;

const activeLine = css`
  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  background-color: #25bfdb;
  z-index: -1;
`;

export const ProgressBar = () => {
  const { step } = stepStore;
  const activeWidth = ((step - 1) * 100) / 7;
  return (
    <div>
      <div className={textStyle}>{`Step ${step}/8`}</div>
      <div className={progressBarStyle}>
        {times(8, i => {
          const stepStyle = () => {
            if (step === i + 1) {
              return currentStep;
            }
            if (step > i + 1) {
              return finishedStep;
            }
            return pendingStep;
          };
          return <div className={stepStyle()} key={i} />;
        })}
        <div className={defaultLine} />
        <div className={activeLine} style={{ width: `${activeWidth}%` }} />
      </div>
    </div>
  );
};
