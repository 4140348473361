import React from 'react';
import Calendar from 'react-calendar/dist/entry.nostyle';
import { css, cx } from 'emotion';

import { useFormikContext, useField } from 'formik';
import moment from 'moment';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8e908e;
  margin-bottom: 50px;
`;

const calendarContainerStyle = css`
  border: 0;
  width: 18rem;
  height: 23rem;
`;

const tileClassName = css`
  border: none;
  font-family: 'Open Sanse', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  color: rgba(64, 64, 66, 0.6);
  margin: 0.5rem 0;
  width: 2.3rem !important;
  height: 2.3rem !important;
  background-color: white;
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    color: #e5e5e5 !important;
  }
`;

const iconStyle = css`
  color: rgba(64, 64, 66, 0.6);
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
`;

const CalendarFieldComponent = props => {
  const { value, onChange, setFieldValue } = props;
  const maxDate = moment().add(30, 'days');

  return (
    <div className={containerStyle}>
      <Calendar
        onChange={onChange}
        value={value}
        activeStartDate={new Date()}
        onClickMonth={() => {
          setFieldValue();
        }}
        minDate={new Date()}
        maxDate={maxDate.toDate()}
        className={calendarContainerStyle}
        tileClassName={tileClassName}
        showNavigation
        prevLabel={
          <i className={cx('material-icons', iconStyle)}>keyboard_arrow_left</i>
        }
        nextLabel={
          <i className={cx('material-icons', iconStyle)}>
            keyboard_arrow_right
          </i>
        }
        formatShortWeekday={(local, date) => moment(date).format('dd')}
        formatMonthYear={(locale, date) => moment(date).format('MMMM, YYYY')}
        maxDetail="month"
        minDetail="month"
      />
    </div>
  );
};

export const CalendarField = props => {
  const { name } = props;

  const formik = useFormikContext();
  const [field] = useField(name);

  const { setFieldValue } = formik;

  return (
    <CalendarFieldComponent
      name={name}
      value={field.value}
      onChange={newValue => setFieldValue(name, newValue)}
    />
  );
};
