import React, { useState } from 'react';
import { getIn, useField, useFormikContext } from 'formik';

export const useFormikField = name => {
  const [focused, setFocus] = useState(false);
  const [field] = useField(name);
  const formik = useFormikContext();

  const setValue = newValueOrEvent => {
    const newValue = newValueOrEvent.target
      ? newValueOrEvent.target.value
      : newValueOrEvent;
    formik.setFieldTouched(name, true, false);
    formik.setFieldValue(name, newValue);
  };

  const touched = getIn(formik.touched, name);
  const errorMessage = getIn(formik.errors, name);
  const isInErrorState = !!errorMessage;
  const onFocus = () => setFocus(true);
  const onBlur = e => {
    setFocus(false);
    field.onBlur(e);
  };

  return {
    ...field,
    setValue,
    isInErrorState,
    onFocus,
    onBlur,
    errorMessage,
    touched,
    focused,
  };
};
