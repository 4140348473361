import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { useFormikField } from '../../utils/use-formik-field';
import plusUrl from './assets/plus.svg';
import minusUrl from './assets/minus.svg';
import plusDisabledUrl from './assets/plus-disabled.svg';
import minusDisabledUrl from './assets/minus-disabled.svg';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const labelStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  text-align: center;
  color: #404042;
  position: relative;
  display: flex;
  align-items: center;
`;

const iconStyle = css`
  width: 2rem;
  height: 2rem;
`;

const valueStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 4rem;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: -0.03em;
  color: #404042;
  margin: 0 1.5rem;
`;

const numberContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const tooltipHolder = css`
  margin-left: 0.5rem;
  transform: scale(80%);
  width: 23px;
  line-height: 23px;
  font-size: 1rem;
  color: white;
  background: #404042;
  border-radius: 50%;
  opacity: 0.7;
  &:hover .tooltip {
    display: block;
  }
  &:hover {
    opacity: 1;
  }
  &:hover::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: #404042;
    position: absolute;
    bottom: 100%;
    transform: rotate(45deg);
  }
`;

const tooltip = css`
  position: absolute;
  background: #404042;
  border-radius: 5px;
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.5rem;
  bottom: calc(100% + 0.25rem);
  right: 0;
  width: 100%;
  display: none;
`;

const NumberFieldComponent = props => {
  const { label, info, max, setValue, value } = props;
  const [numberValue, setNumberValue] = useState(value);

  const handleAddValue = () => {
    if (numberValue < max) {
      setNumberValue(numberValue + 1);
      setValue(numberValue + 1);
    }
  };

  const handleSubtractValue = () => {
    if (numberValue > 0) {
      setNumberValue(numberValue - 1);
      setValue(numberValue - 1);
    }
  };

  return (
    <div className={containerStyle}>
      <div className={labelStyle}>
        {label}
        {info && (
          <span className={tooltipHolder}>
            ?<div className={cx(tooltip, 'tooltip')}>{info}</div>
          </span>
        )}
      </div>
      <div className={numberContainerStyle}>
        <img
          className={iconStyle}
          src={numberValue <= 0 ? minusDisabledUrl : minusUrl}
          alt="minus"
          onClick={handleSubtractValue}
        />
        <div className={valueStyle}>{numberValue}</div>
        <img
          className={iconStyle}
          src={numberValue >= max ? plusDisabledUrl : plusUrl}
          alt="plus"
          onClick={handleAddValue}
        />
      </div>
    </div>
  );
};

export const NumberField = props => {
  const { name, label, max, info, id } = props;

  const { value, setValue } = useFormikField(name);

  return (
    <NumberFieldComponent
      name={name}
      id={id}
      value={value || 0}
      label={label}
      info={info}
      max={max}
      setValue={setValue}
    />
  );
};
