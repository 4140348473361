export const initialValues = {
  firstName: '',
  levelOfCover: '',
  howManyAdults: 0,
  howManyChildren: 0,
  EffectivePeriodStartDate: new Date(),
  title: {
    code: '',
    description: '',
  },
  lastName: '',
  email: '',
  telephone: '',
  dateOfBirth: '',
  accountName: '',
  accountNumber: '',
  sortCode: '',
  collectionDate: {
    code: '',
    description: '',
  },
  address: {
    postcode: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
  },
  yearsTrading: 0,
  legalStatus: {
    code: '',
    description: '',
  },
  tradingAs: {
    code: '',
    description: '',
  },
  companyName: '',
  namedParty: [],
  assumptions: false,
  declarationsBox: false,
};
