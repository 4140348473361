import silverUrl from "../components/components/assets/silverPremium.png";
import goldUrl from "../components/components/assets/goldPremium.png";
import platinumUrl from "../components/components/assets/platinumPremium.png";

export const levelOfCoverData = [
  {
    code: 'SILVER',
    value: 'Silver',
    imgUrl: silverUrl,
    dsc: '£9.75',
    primaryValue: 9.75,
    additionalAdultValue: 9.25,
    additionalChildValue: 4.05,
    core: [
      '• £35 for dental check-ups & x-rays',
      '• £50 for scale and polish',
      '• £260 for fillings, extractions, dentures treatment etc',
    ],
    additional: [
      '• Up to £850 for Worldwide emergency treatment per year*',
      '• Up to £5,000 for Worldwide dental accidents per year*',
      '• £50 per night for Hospital Stays (up to 20 nights per year)',
      '• £5,000 Mouth cancer cover (lump sum payment – lifetime limit)',
      '• ...and more!'
    ],
  },
  {
    code: 'GOLD',
    value: 'Gold',
    imgUrl: goldUrl,
    dsc: '£15.20',
    primaryValue: 15.2,
    additionalAdultValue: 14.4,
    additionalChildValue: 6.4,
    core: [
      '• £70 for dental check-ups & x-rays ',
      '• £50 for scale and polish',
      '• £450 for fillings, extractions & dentures etc',
      '• £285 for crowns, root canal, inlays/onlays & bridges',
    ],
    additional: [
      '• Up to £950 for Worldwide emergency treatment per year*',
      '• Up to £7,500 for Worldwide dental accidents per year*',
      '• £50 per night for Hospital Stays (up to 20 nights per year)',
      '• £7,500 Mouth cancer cover (lump sum payment – lifetime limit)',
      '• £50 towards teeth whitening treatment with MyDentist',
      '• ...and more!'
    ],
  },
  {
    code: 'PLATINUM',
    value: 'Platinum',
    imgUrl: platinumUrl,
    dsc: '£20.85',
    primaryValue: 20.85,
    additionalAdultValue: 19.85,
    additionalChildValue: 8.75,
    core: [
      '• £70 for dental check-ups & x-rays ',
      '• £100 for scale and polish',
      '• £610 for fillings, extractions & dentures etc',
      '• £310 for crowns, root canal, inlays/onlays & bridges',
    ],
    additional: [
      '• Up to £1,100 for Worldwide emergency treatment per year*',
      '• Up to £10,000 for Worldwide dental accidents per year*',
      '• £50 per night for Hospital Stays (up to 20 nights per year)',
      '• £10,000 Mouth cancer cover (lump sum payment – lifetime limit)',
      '• £50 towards teeth whitening treatment with MyDentist',
      '• ...and more!'
    ],
  },
];
