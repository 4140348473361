import React from 'react';
import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { CheckboxField } from '../../components/CheckboxField';
import { QuoteDetails } from '../../components/QuoteDetails';
import { quoteStore } from '../../../store/quote-store';
import { Loader } from '../../components/Loader';

const tagStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5em;
  margin-bottom: 0.5rem;
`;

const rowStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

const contentContainer = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const dataContainer = css`
  flex: 1;
  margin-right: 5rem;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
`;

const linkStyle = css`
  text-decoration: none;
  color: #4fbfdb;
  font-weight: 600;
  &:hover {
    color: #6bd4ef;
  }
`

export const Assumptions = observer(props => {
  const { handleNextStep, formik } = props;
  return (
    <Container>
      {quoteStore.isSubmitting && (
        <Loader message="Finalising your policy..." />
      )}
      {!quoteStore.isSubmitting && (
        <>
          <Title title="Please read below statements thoroughly" />
          <div className={contentContainer}>
            <div className={dataContainer}>
              <div>
                <p className={tagStyle}>Assumptions:</p>
                <p className={rowStyle}>You are a resident in the UK.</p>
                <p className={rowStyle}>You are over the age of 18.</p>
              </div>
              <div>
                <p className={tagStyle}>Declaration:</p>
                <p className={rowStyle}>
                  This policy generally meets the demands and needs of someone
                  wishing to help with the cost of everyday dental expenses, as
                  well as dental emergency treatments, dental injuries and oral
                  cancer. We provide information for you to come to your own
                  decision whether this insurance is suitable for you.
                </p>
                <p className={rowStyle}>
                  This is a monthly renewable policy. This means your policy
                  will renew automatically every month for which we receive and
                  accept the premium, until your policy ends.
                </p>
                <p className={rowStyle}>
                  We'll collect your 1st premium around 14 working days after
                  you initially sign up. Please note that as we collect premiums
                  1 month in advance, we'll make an additional collection to
                  cover you for month 2 in your 1st month. When this second
                  premium leaves your account depends on your nominated Direct
                  Debit payment day.
                  Read our <a href={'https://www.dencover.com/faq/'} target={'_blank'} className={linkStyle}>FAQs </a>
                  for more information.
                </p>
                <p className={rowStyle}>
                  The company name that will appear on your bank statement
                  against the Direct Debit will be: Unum Dental.
                </p>
              </div>
              <div>
                <CheckboxField
                  label="I Agree to the Assumptions"
                  name="assumptions"
                />
                <CheckboxField
                  label="I Agree to the Declaration"
                  name="declarationsBox"
                />
              </div>
            </div>
            <QuoteDetails formik={formik} />
          </div>
          <ButtonsContainer quoteScreen>
            <CustomButton
              handleClick={() => stepStore.previousStep()}
              label="previous step"
              secondary
            />
            <CustomButton handleClick={handleNextStep} label="next step" />
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
});
