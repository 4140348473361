import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';

import { css } from 'emotion';
import { stepStore } from '../../store/step-store';
import { ChoosePremium } from './components/ChoosePremium';
import { StartDate } from './components/StartDate';
import { AdditionalMembers } from './components/AdditionalMembers';
import { QuoteValue } from './components/QuoteValue';
import { PersonalDetails } from './components/PersonalDetails';
import { AdditionalMemberDetails } from './components/AdditionalMembersDetails';
import { BankDetails } from './components/BankDetails';
import { Assumptions } from './components/Assumptions';
import { ProgressBar } from '../components/ProgressBar';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const appContainerStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const flexStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`;

export const AppForm = withRouter(
  observer(() => {
    const { step } = stepStore;
    const formik = useFormikContext();

    const handleNextStep = () => {
      formik.handleSubmit();
    };

    return (
      <div className={flexStyle}>
        <Header />
        <div className={appContainerStyle}>
          <ProgressBar />
          {step === 1 && <ChoosePremium handleNextStep={handleNextStep} />}
          {step === 2 && <StartDate handleNextStep={handleNextStep} />}
          {step === 3 && <AdditionalMembers handleNextStep={handleNextStep} />}
          {step === 4 && (
            <QuoteValue handleNextStep={handleNextStep} formik={formik} />
          )}
          {step === 5 && (
            <PersonalDetails handleNextStep={handleNextStep} formik={formik} />
          )}
          {step === 6 && (
            <AdditionalMemberDetails
              handleNextStep={handleNextStep}
              formik={formik}
            />
          )}
          {step === 7 && (
            <BankDetails handleNextStep={handleNextStep} formik={formik} />
          )}
          {step === 8 && (
            <Assumptions handleNextStep={handleNextStep} formik={formik} />
          )}
        </div>
        <Footer />
      </div>
    );
  })
);
