import React from 'react';

import { css } from 'emotion';
import { observer } from 'mobx-react';
import logo from '../../assets/logo.svg';
import { Container } from '../components/Container';
import { stepStore } from '../../store/step-store';
import { CustomButton } from '../components/CustomButton';
import { ButtonsContainer } from '../components/ButtonsContainer';
import { quoteStore } from '../../store/quote-store';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const imageStyle = css`
  height: 9rem;
  align-self: center;
  margin: 3rem 0 2rem 0;
  @media only screen and (max-width: 600px) {
    height: 6rem;
  }
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const titleStyle = css`
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.1em;
  color: #404042;
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;

const dscStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5em;
  text-align: center;
  color: #404042;
`;

const linkStyle = css`
  text-decoration: none;
  color: #3ebaf0;
  font-weight: 600;
`;

const flexStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`;

export const Success = observer(() => {
  const handleGoBack = () => {
    stepStore.step = 1;
    window.location.href = 'https://quote.yourdentalcover.co.uk/';
  };

  return (
    <div className={flexStyle}>
      <Header />
      <Container>
        <div className={textContainer}>
          <p className={titleStyle}>
            Thank you for purchasing dental insurance with Your Dental Cover
          </p>
          <p className={dscStyle}>
            You will receive your policy documents within the next three working
            days.
          </p>
          <p className={dscStyle}>
            In the meantime, if you have any questions, don't hesitate to get in
            touch with us by emailing{' '}
            <a href="mailto:info@dencover.com" className={linkStyle}>
              info@dencover.com
            </a>
            .
          </p>
          <p className={dscStyle}>
            Your application number is{' '}
            <span className={linkStyle}>{quoteStore.policyId}</span>
          </p>
          <img src={logo} alt="success" className={imageStyle} />
        </div>
        <ButtonsContainer>
          <CustomButton handleClick={handleGoBack} label="back to home" />
        </ButtonsContainer>
      </Container>
      <Footer />
    </div>
  );
});
