import React, { useState } from 'react';
import { css } from 'emotion';
import { Modal } from './Modal';
import { quoteStore } from '../../store/quote-store';

const quoteContainerStyle = css`
  background: white;
  padding: 1rem;
  border-top: 2px solid #25bfdb;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const priceContainerStyle = css`
  flex-grow: 1;
`;

const priceStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.2em;
  color: #404042;
`;

const linkStyle = css`
  text-transform: uppercase;
  color: #25bfdb;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

const labelStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #404042;
`;

const showOnMobile = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const showOnDesktop = css`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 2rem 1.7rem 2.5rem 1.375rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const titleStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #404042;
`;

const dscStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5em;
  color: #737373;
`;

const totalAmountStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #25bfdb;
  display: inline-block;
`;

const tagStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #404042;
  margin-top: 1.125rem;
`;

const priceAmountStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #25bfdb;
`;

const coverTypeSpecificValues = {
  Silver: {
    type: 'SILVER',
    primaryValue: 9.75,
    additionalAdultValue: 9.25,
    additionalChildValue: 4.05,
  },
  Gold: {
    type: 'GOLD',
    primaryValue: 15.2,
    additionalAdultValue: 14.4,
    additionalChildValue: 6.4,
  },
  Platinum: {
    type: 'PLATINUM',
    primaryValue: 20.85,
    additionalAdultValue: 19.85,
    additionalChildValue: 8.75,
  },
};

const getBreakDownPrice = (mobile, formik) => {
  const coverValues = coverTypeSpecificValues[formik.values.levelOfCover];
  const totalAmount =
    (quoteStore.underwrittenPrice.totalAmount / 12).toFixed(2) || '000.00';

  return (
    <div>
      {!mobile && <div className={titleStyle}>Total monthly</div>}
      <div className={totalAmountStyle}>£{totalAmount}</div>
      <div className={tagStyle}>Primary insured</div>
      <div className={priceAmountStyle}>
        £{coverValues.primaryValue.toFixed(2)}
      </div>
      <div className={tagStyle}>
        Additional adults ({formik.values.howManyAdults})
      </div>
      <div className={priceAmountStyle}>
        £
        {(
          coverValues.additionalAdultValue * formik.values.howManyAdults
        ).toFixed(2)}
      </div>
      <div className={tagStyle}>
        Additional children ({formik.values.howManyChildren})
      </div>
      <div className={priceAmountStyle}>
        £
        {(
          coverValues.additionalChildValue * formik.values.howManyChildren
        ).toFixed(2)}
      </div>
    </div>
  );
};

export const QuoteDetails = props => {
  const { formik } = props;
  const [showModal, setShowModal] = useState(false);
  const totalAmount =
    (quoteStore.underwrittenPrice.totalAmount / 12).toFixed(2) || '000.00';

  return (
    <div>
      <div className={showOnDesktop}>{getBreakDownPrice(false, formik)}</div>
      <div className={showOnMobile}>
        <div className={quoteContainerStyle}>
          <div className={priceContainerStyle}>
            <p className={priceStyle}>£{totalAmount}</p>
            <p className={labelStyle}>Your quote</p>
          </div>
          <div className={linkStyle} onClick={() => setShowModal(true)}>
            Price breakdown
          </div>
        </div>
        {showModal && (
          <Modal
            handleClose={() => setShowModal(false)}
            show={showModal}
            title="Price breakdown"
          >
            {getBreakDownPrice(true, formik)}
          </Modal>
        )}
      </div>
    </div>
  );
};
