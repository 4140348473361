import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { AppForm } from '../AppForm/AppForm';
import { initialValues } from '../../validation/initialValues';
import { validationSchema } from '../../validation/validationSchema';
import { stepStore } from '../../store/step-store';
import useFirebase from '../Firebase/useFirebase';
import { quoteStore } from '../../store/quote-store';
import { lookupStore } from '../../store/lookup-store';

export const Main = withRouter(
  observer(props => {
    const firebase = useFirebase();

    useEffect(() => {
      async function fetchLookups() {
        const lookups = await firebase.doGetLookups();
        const {
          titles: lookupTitles,
          paymentDetails: lookupPaymentDetails,
        } = lookups;

        lookupStore.titles = lookupTitles;
        lookupStore.paymentDetails = lookupPaymentDetails;
      }

      fetchLookups();
    }, [firebase]);

    const getFilteredNamedPart = (namedParty) => {
      return namedParty.map(value => {
        return {
          ...value,
          dob: moment(value.dob, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }
      });
    }

    const handleSubmit = async (values, formikBag) => {
      try {
        const { step, nextStep, skipNSteps } = stepStore;
        if (step === 3) {
          quoteStore.isSubmitting = true;
          const result = await firebase.doRateQuote(
            {
              ...values,
              dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              inceptionDate: moment(values.EffectivePeriodStartDate).format(
                'YYYY-MM-DD'
              ),
              product: 'MYDENTIST',
            },
            null
          );
          quoteStore.saveQuoteData(result);
          quoteStore.isSubmitting = false;
          nextStep();
        } else if (
          step === 5 &&
          !(values.howManyAdults || values.howManyChildren)
        ) {
          skipNSteps(2);
        } else if (step < 8) {
          nextStep();
        } else if (step === 8) {
          quoteStore.isSubmitting = true;
          const newNamedParty = getFilteredNamedPart(values.namedParty);
          const saveResult = await firebase.doSavePolicyMyDentist({
            ...values,
            namedParty: newNamedParty,
            dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            inceptionDate: moment(values.EffectivePeriodStartDate).format(
              'YYYY-MM-DD'
            ),
            product: 'MYDENTIST',
          });
          quoteStore.policyId = saveResult.data.policyId;
          props.history.push('/success');
          quoteStore.isSubmitting = false;
        }
        formikBag.setTouched({}, false);
        window.scrollTo(0, 0);
      } catch (e) {
        Sentry.captureMessage(e);
        props.history.push('/error');
      }
    };

    const getValidationScheme = () => validationSchema[stepStore.step - 1];

    return (
      <div className="App">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={getValidationScheme()}
        >
          <AppForm />
        </Formik>
      </div>
    );
  })
);
