import React from 'react';
import { withRouter } from 'react-router-dom';

import { css } from 'emotion';
import errorImage from '../../assets/error-state.svg';
import { Container } from '../components/Container';
import { stepStore } from '../../store/step-store';
import { CustomButton } from '../components/CustomButton';
import { ButtonsContainer } from '../components/ButtonsContainer';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const imageStyle = css`
  height: 9rem;
  width: 14.244rem;
  align-self: center;
  margin: 6rem 0 2rem 0;
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const titleStyle = css`
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.1em;
  color: #404042;
  margin-bottom: 1.5rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;

const dscStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5em;
  text-align: center;
  color: #404042;
`;

const flexStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`;

export const Error = withRouter(props => {
  const handleGoBack = () => {
    stepStore.step = 1;
    props.history.push('/');
  };

  return (
    <div className={flexStyle}>
      <Header />
      <Container>
        <div className={textContainer}>
          <p className={titleStyle}>An error has occurred.</p>
          <p className={dscStyle}>
            Unfortunately an error has occurred. Please try again later.
          </p>
          <img src={errorImage} alt="error" className={imageStyle} />
        </div>
        <ButtonsContainer>
          <CustomButton handleClick={handleGoBack} label="try again" />
        </ButtonsContainer>
      </Container>
      <Footer />
    </div>
  );
});
