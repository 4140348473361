import React from 'react';
import { css } from 'emotion';

import checkUrl from './assets/check.svg';
import { useFormikField } from '../../utils/use-formik-field';

const containerStyle = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #404042;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const checkboxContainer = css`
  margin-right: 1rem;
  border-radius: 4px;
  overflow: hidden;
  :focus {
    outline: none !important;
  }
  :hover {
    background-color: rgba(93, 93, 93, 0.35);
  }
`;

const onStyle = css`
  background-color: #25bfdb;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const offStyle = css`
  background-color: #e5e5e5;
  width: 1.5rem;
  height: 1.5rem;
`;

const iconStyle = css`
  width: 1rem;
  height: 1rem;
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  margin-top: 0.5rem;
`;

const wrapperStyle = css`
  margin: 1rem 0rem;
`;

const CheckboxFieldComponent = props => {
  const { value, onChange, label, error, errorMessage, touched } = props;
  return (
    <div className={wrapperStyle}>
      <div className={containerStyle}>
        <div
          role="button"
          tabIndex={0}
          className={checkboxContainer}
          onClick={() => onChange(!value)}
          onKeyDown={() => onChange(!value)}
        >
          <div className={value ? onStyle : offStyle}>
            {value && <img src={checkUrl} alt="check" className={iconStyle} />}
          </div>
        </div>
        {label}
      </div>
      {error && touched && <p className={errorStyle}>{errorMessage}</p>}
    </div>
  );
};

export const CheckboxField = props => {
  const { label, name } = props;

  const {
    value,
    setValue,
    errorMessage,
    touched,
    isInErrorState,
  } = useFormikField(name);

  return (
    <CheckboxFieldComponent
      label={label}
      name={name}
      value={value}
      onChange={setValue}
      error={isInErrorState}
      errorMessage={errorMessage}
      touched={touched}
    />
  );
};
