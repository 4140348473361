import React from 'react';
import { Input, InputGroupAddon, Button, InputGroup } from 'reactstrap';
import { css, cx } from 'emotion';

import { useFormikField } from '../../utils/use-formik-field';

const inputContainerStyle = css`
  margin: 0 0 1rem 0;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

const disabledStyle = css`
  border-color: #b9b9b9 !important;
  border-radius: 6px;
  background-color: #b9b9b9 !important;
  color: white !important;
`;

const customInputStyle = css`
  width: 100%;
  padding: 0.7rem 1rem;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  outline: none;
  color: #929090;
  left: 0;
  top: 0;
  &:focus {
    font-size: 16px;
    top: 1.1rem;
    padding: 0.1rem 1rem 0.3rem 1rem;
    transition: all 0.25s ease-out;
    + label {
      color: #25bfdb;
      background-color: transparent;
      transition: all 0.25s ease-out;
      transform: translate(0, -1.2rem);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
    }
    ~ .borderDiv {
      box-shadow: 0 0 0 2px #25bfdb;
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const groupButtonStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  cursor: pointer;
  color: #25bfdb;
  border: 2px solid #25bfdb;
  border-radius: 4px;
  margin-left: 1rem;
  height: 3.2rem;
  padding: 0 1rem;
  background: transparent;
  display: inline;
  &:focus {
    outline: none;
  }
`;

const stretchedContainerStyle = css`
  display: flex;
  align-items: stretch;
`;

const labelStyle = css`
  width: calc(100% - 1.8rem);
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  color: #aeaeae;
  pointer-events: none;
`;

const customInputContainer = css`
  position: relative;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  flex: 1;
  height: 3.2rem;
`;

const hasValueState = css`
  label {
    color: #aeaeae;
    background-color: transparent;
    transition: all 0.25s ease-out;
    transform: translate(0, -1.2rem);
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  input {
    font-size: 16px;
    top: 1.1rem;
    padding: 0.1rem 1rem 0.3rem 1rem;
    transition: all 0.25s ease-out;
  }
`;

const borderStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 2px;
`;

export const InputComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    type,
    onBlur,
    error,
    invalid,
    placeholder,
    onAddOnClick,
    addOnLabel,
    touched,
    disabled,
    min,
    onChangeSideEffect,
    required,
    size,
    onFocus,
    focused,
  } = props;

  const handleChange = e => {
    onChangeSideEffect(e.target.value);
    onChange(e);
  };

  const getSizeStyle = () => {
    switch (size) {
      case 's':
        return '20%';
      case 'm':
        return '49%';
      case 'l':
        return '100%';
      default:
        return '100%';
    }
  };

  return (
    <div className={inputContainerStyle} style={{ width: getSizeStyle() }}>
      <InputGroup className={stretchedContainerStyle}>
        <div className={cx(customInputContainer, value && hasValueState)}>
          <Input
            type={type}
            name={name}
            id={id}
            value={value}
            invalid={invalid}
            onChange={onChangeSideEffect ? handleChange : onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            min={min}
            required={required}
            className={disabled ? disabledStyle : customInputStyle}
          />
          <label className={labelStyle}>{placeholder}</label>
          <div className={cx(borderStyle, 'borderDiv')} />
        </div>
        {onAddOnClick && addOnLabel && (
          <InputGroupAddon addonType="append">
            <Button
              color="secondary"
              onClick={e => onAddOnClick(e, value)}
              disabled={disabled}
              className={groupButtonStyle}
            >
              {addOnLabel}
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {invalid && touched && !focused && <div className={errorStyle}>{error}</div>}
    </div>
  );
};

export const InputField = props => {
  const {
    label,
    name,
    id,
    type,
    placeholder,
    onAddOnClick,
    addOnLabel,
    disabled,
    min,
    onChangeSideEffect,
    required,
    fixedValue,
    size,
  } = props;

  const {
    value,
    onBlur,
    setValue,
    isInErrorState,
    errorMessage,
    touched,
    onFocus,
    focused,
  } = useFormikField(name);

  return (
    <InputComponent
      name={name}
      id={id}
      size={size}
      label={label}
      type={type}
      value={fixedValue || value || ''}
      invalid={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onAddOnClick={onAddOnClick}
      addOnLabel={addOnLabel}
      onBlur={onBlur}
      onFocus={onFocus}
      focused={focused}
      touched={touched}
      disabled={disabled}
      min={min}
      onChangeSideEffect={onChangeSideEffect}
      required={required}
    />
  );
};
