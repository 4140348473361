import React from 'react';
import { css } from 'emotion';
import { times } from 'lodash';
import { Container } from '../../components/Container';
import { Title } from '../../components/Title';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { QuoteDetails } from '../../components/QuoteDetails';
import { SelectField } from '../../components/SelectField';
import { InputField } from '../../components/InputField';
import { lookupStore } from '../../../store/lookup-store';
import { useFormikField } from '../../../utils/use-formik-field';
import {NumberFormatField} from "../../components/NumberFormatField";

const contentContainer = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const inputContainer = css`
  flex: 1;
  margin-right: 5rem;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
`;

const sharedRowStyle = css`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 1.5%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const rowStyle = css`
  display: flex;
  flex: 1;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const labelStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #404042;
  margin-bottom: 1rem;
`;

const dividerStyle = css`
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin: 1rem 0 1rem 0;
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

export const AdditionalMemberDetails = props => {
  const { handleNextStep, formik } = props;
  const adults = formik.values.howManyAdults;
  const children = formik.values.howManyChildren;

  const { isInErrorState, touched } = useFormikField('namedParty');

  const getInfoFields = i => (
    <>
      <div className={rowStyle}>
        <SelectField
          formikName={`namedParty[${i}].title`}
          placeholder="Title"
          size="s"
          options={lookupStore.titles || []}
        />
        <div className={sharedRowStyle}>
          <InputField
            name={`namedParty[${i}].firstName`}
            id={`namedParty[${i}].firstName`}
            placeholder="First Name"
            size="m"
          />
          <InputField
            name={`namedParty[${i}].lastName`}
            id={`namedParty[${i}].lastName`}
            placeholder="Last Name"
            size="m"
          />
        </div>
      </div>
      {/*<InputField*/}
        {/*name={`namedParty[${i}].dob`}*/}
        {/*type="date"*/}
        {/*id={`namedParty[${i}].dob`}*/}
        {/*placeholder="Date of birth"*/}
        {/*size="m"*/}
      {/*/>*/}
      <NumberFormatField
        name={`namedParty[${i}].dob`}
        id={`namedParty[${i}].dob`}
        placeholder="Date of birth"
        size="m"
        label="Date of birth*"
        type="number"
        numberFormatPlaceholder="dd/mm/yyyy"
        format="##/##/####"
        mask={['d', 'd', 'm', 'm', 'y', 'y', 'y', 'y']}
      />
    </>
  );

  const getAdults = () =>
    times(adults, i => (
      <div id={`adult${i}`}>
        <div className={labelStyle}>{`Additional adult #${i + 1}`}</div>
        {getInfoFields(i)}
      </div>
    ));

  const getChildren = () =>
    times(children, i => (
      <div id={`child${i}`}>
        <div className={labelStyle}>{`Additional child #${i + 1}`}</div>
        {getInfoFields(adults + i)}
      </div>
    ));

  return (
    <Container>
      <Title title="Additional data for other members" />
      <div className={contentContainer}>
        <div className={inputContainer}>
          {getAdults()}
          {adults > 0 && children > 0 && <div className={dividerStyle} />}
          {getChildren()}
          {isInErrorState && touched && (
            <p className={errorStyle}>
              All details about your additional members are required fields.
            </p>
          )}
        </div>
        <QuoteDetails formik={formik} />
      </div>
      <ButtonsContainer quoteScreen>
        <CustomButton
          handleClick={() => stepStore.previousStep()}
          label="previous step"
          secondary
        />
        <CustomButton handleClick={handleNextStep} label="next step" />
      </ButtonsContainer>
    </Container>
  );
};
