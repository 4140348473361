import { observable } from 'mobx';

export class PostCodeStore {
  @observable
  postcodeAutoComplete = [];

  @observable
  searchAddressInProgress = false;
}

export const postCodeStore = new PostCodeStore();
