import React from 'react';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { CalendarField } from '../../components/CalendarField';

export const StartDate = props => {
  const { handleNextStep } = props;
  return (
    <Container>
      <Title title="When would you like your cover to start?" />
      <CalendarField
        name="EffectivePeriodStartDate"
        id="EffectivePeriodStartDate"
        label=""
      />
      <ButtonsContainer>
        <CustomButton
          handleClick={() => stepStore.previousStep()}
          label="previous step"
          secondary
        />
        <CustomButton handleClick={handleNextStep} label="next step" />
      </ButtonsContainer>
    </Container>
  );
};
