import React from 'react';
import { css, cx } from 'emotion';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { InputField } from '../../components/InputField';
import { QuoteDetails } from '../../components/QuoteDetails';
import { SelectField } from '../../components/SelectField';
import { AddressInfoFields } from '../../components/AddressInfoFileds';
import { lookupStore } from '../../../store/lookup-store';
import { useFormikField } from '../../../utils/use-formik-field';
import {NumberFormatField} from "../../components/NumberFormatField";

const tagStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #404042;
  margin-bottom: 1rem;
`;

const errorStyle = css`
  color: #dc3545;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
`;

const contentContainer = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 1rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const inputContainer = css`
  flex: 1;
  margin-right: 5rem;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
  }
`;

const sharedRowStyle = css`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const topMargin = css`
  margin-top: 1.5rem;
`;

export const PersonalDetails = props => {
  const { handleNextStep, formik } = props;

  const { isInErrorState: isInErrorAddress } = useFormikField('address');

  const { isInErrorState: isInErrorPostcode } = useFormikField(
    'address.postcode'
  );

  const { touched: touchedAddressOther } = useFormikField(
    'address.addressLine1'
  );

  return (
    <Container>
      <Title title="Give us some more info about yourself" />
      <div className={contentContainer}>
        <div className={inputContainer}>
          <p className={tagStyle}>Personal information</p>
          <SelectField
            formikName="title"
            placeholder="Title"
            size="s"
            options={lookupStore.titles || []}
          />
          <div className={sharedRowStyle}>
            <InputField
              name="firstName"
              id="firstName"
              placeholder="First Name"
              size="m"
            />
            <InputField
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              size="m"
            />
          </div>
          <p className={cx(tagStyle, topMargin)}>Contact information</p>
          <InputField name="email" id="email" placeholder="E-mail" size="l" />
          <InputField
            name="telephone"
            id="telephone"
            type="number"
            placeholder="Phone"
            size="m"
          />
          <NumberFormatField
            name="dateOfBirth"
            id="dateOfBirth"
            placeholder="Date of birth"
            size="m"
            label="Date of birth*"
            type="number"
            numberFormatPlaceholder="dd/mm/yyyy"
            format="##/##/####"
            mask={['d', 'd', 'm', 'm', 'y', 'y', 'y', 'y']}
          />
          <AddressInfoFields />
          {!isInErrorPostcode && touchedAddressOther && isInErrorAddress && (
            <p className={errorStyle}>
              {'All address fields have to be provided'}
            </p>
          )}
        </div>
        <QuoteDetails formik={formik} />
      </div>
      <ButtonsContainer quoteScreen>
        <CustomButton
          handleClick={() => stepStore.previousStep()}
          label="previous step"
          secondary
        />
        <CustomButton handleClick={handleNextStep} label="next step" />
      </ButtonsContainer>
    </Container>
  );
};
