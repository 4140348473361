import React from 'react';
import { observer } from 'mobx-react';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { NumberField } from '../../components/NumberField';
import { quoteStore } from '../../../store/quote-store';
import { Loader } from '../../components/Loader';

export const AdditionalMembers = observer(props => {
  const { handleNextStep } = props;
  return (
    <Container>
      {quoteStore.isSubmitting && <Loader message="Generating your quote..." />}
      {!quoteStore.isSubmitting && (
        <>
          <Title title="You can also add additional family members to your dental cover if required" />
          <div>
            <NumberField
              name="howManyAdults"
              label="Additional adults"
              max={3}
              info="By additional adult, we mean your spouse, partner,
              siblings, children above 18 years old or parents who live in the same property as you.
              Up to 3 additional adults are allowed on the same level of cover as you."
            />
            <NumberField
              name="howManyChildren"
              label="Additional children"
              max={4}
              info="By children we mean a natural or legally-adopted child of you or your spouse,
              civil partner or partner - aged between 6 months and 18 years at the time of treatment
              and permanently living with you. Up to 4 children are allowed."
            />
          </div>
          <ButtonsContainer>
            <CustomButton
              handleClick={() => stepStore.previousStep()}
              label="previous step"
              secondary
            />
            <CustomButton handleClick={handleNextStep} label="next step" id="GETQUOTE"/>
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
});
