import { observable, toJS } from 'mobx';
import { get } from 'lodash';

export class LookupStore {
  @observable
  titles = [];

  paymentDetails = [];

  findTitleNameByCode = code =>
    get(toJS(this.titles).filter(title => title.code === code), '[0].value');
}

export const lookupStore = new LookupStore();
