import React from 'react';
import { css, cx } from 'emotion';

const containerStyle = css`
  width: 100%;
  display: flex;
  max-width: 1180px;
  margin: 1rem 0;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const bottomMargin = css`
  @media only screen and (max-width: 768px) {
    margin-bottom: 7rem;
  }
`;

export const ButtonsContainer = props => {
  const { quoteScreen } = props;
  return (
    <div
      className={
        quoteScreen ? cx(containerStyle, bottomMargin) : containerStyle
      }
    >
      {props.children}
    </div>
  );
};
