import React, {useState} from 'react';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import { CustomButton } from '../../components/CustomButton';
import { stepStore } from '../../../store/step-store';
import { ButtonsContainer } from '../../components/ButtonsContainer';
import { quoteStore } from '../../../store/quote-store';
import { Loader } from '../../components/Loader';
import {levelOfCoverData} from "../../../contants/level-of-cover";
import {find} from 'lodash';

const premiumContainer = css`
  display: flex;
  flex-direction: column;
  background: white;
  width: 23rem;
  min-height: 17rem;
  border-radius: 4px;
  border: none;
  box-shadow: 1px 7px 6px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  @media only screen and (max-width: 600px) {
    width: 20rem;
  }
`;

const headerStyle = css`
  width: 100%;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 1.25rem 2rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const iconStyle = css`
  height: 3.125rem;
`;

const premiumTitle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5em;
  text-align: center;
  text-transform: uppercase;
  color: #404042;
  margin-left: 3rem;
  @media only screen and (max-width: 600px) {
    margin-left: 0.2rem;
  }
`;

const textContainerStyle = css`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const priceStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
  color: #25bfdb;
`;

const dscStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5em;
  text-align: center;
  color: #737373;
  margin-top: 0.5rem;
`;

const detailContainerStyle = css`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 1rem 0 3rem 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0;
  }
`;

const breakdownContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
    align-items: flex-start;
  }
`;

const labelStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5em;
  color: #404042;
`;

const breakDownStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;
  color: #404042;
  text-align: center;
  margin: 3rem 0 1rem 0;
`;

const breakdownPriceStyle = css`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  text-transform: uppercase;
  color: #25bfdb;
`;

const additionalStyle = css`
  margin-top: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  color: #a6a4a4;
  text-align: left;
`;

const subtitleStyle = css`
  font-weight: bold;
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: #25bfdb;
`

const rowStyle = css`
  margin-bottom: 5px;
`;

const showMoreStyle = css`
  color: #25bfdb;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin: 8px 0px;
  text-align: center;
  cursor: pointer;
`

const offerText = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  color: #404042;
  padding: 1rem;
  border-radius: 4px;
  background: #effdff;
  border: 2px solid #25bfdb;
  text-align: center;
  margin-top: 16px;
`;

const additionalContainer = css`
  text-align: left;
  padding: 0 1rem;
`


const PriceBreakdownComponent = props => {
  const { label, price } = props;
  return (
    <div className={breakdownContainer}>
      <span className={labelStyle}>{label}</span>
      <span className={breakdownPriceStyle}>{price}</span>
    </div>
  );
};

export const QuoteValue = observer(props => {
  const { handleNextStep, formik } = props;
  const [showMore, setShowMore] = useState(false);
  const [showPrices, setShowPrices] = useState(false);

  const coverValues = find(levelOfCoverData, (o) => o.value === formik.values.levelOfCover);

  const totalAmount = `£${(
    quoteStore.underwrittenPrice.totalAmount / 12
  ).toFixed(2) || '000.00'}`;

  return (
    <Container>
      {quoteStore.isSubmitting && <Loader message="Generating your quote..." />}
      {!quoteStore.isSubmitting && (
        <>
          <Title title="We’re done crunching the numbers. Here’s your quote!" />
          <div className={premiumContainer}>
            <div className={headerStyle}>
              <img
                src={coverValues.imgUrl}
                className={iconStyle}
                alt="premium icon"
              />
              <span className={premiumTitle}>
                {coverValues.type} MEMBERSHIP
              </span>
            </div>
            <div className={textContainerStyle}>
              <div className={priceStyle}>{totalAmount}</div>
              <div className={dscStyle}>Total Monthly Premium</div>
              <p className={showMoreStyle} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less...':"What's covered?"}</p>
              {showMore &&
                <div className={additionalContainer}>
              <div className={additionalStyle}>
                <p className={subtitleStyle}>CORE COVERS</p>
                {coverValues.core.map((a, id) => (
                  <p id={id} className={rowStyle}>
                    {a}
                  </p>
                ))}
              </div>
              <div className={additionalStyle}>
                <p className={subtitleStyle}>ADDITIONAL COVERS</p>
                {coverValues.additional.map((a, id) => (
                  <p id={id} className={rowStyle}>
                    {a}
                  </p>
                ))}
              </div></div>}
            </div>
          </div>
          <p className={offerText}>With every policy taken out with YourDentalCover we offer a FREE Philips Sonicare Dailyclean Electric Toothbrush worth up to £50.00!</p>
          <p className={showMoreStyle} onClick={() => setShowPrices(!showPrices)}>{showPrices ? 'Show less...':"Price breakdown"}</p>
          {showPrices && <>
            <p className={breakDownStyle}>Here is your quote breakdown:</p>
            <div className={detailContainerStyle}>
              <PriceBreakdownComponent
                label="Primary insured"
                price={`£${coverValues.primaryValue.toFixed(2)}`}
              />
              <PriceBreakdownComponent
                label={`Additional adults (${formik.values.howManyAdults})`}
                price={`£${(
                  coverValues.additionalAdultValue * formik.values.howManyAdults
                ).toFixed(2)}`}
              />
              <PriceBreakdownComponent
                label={`Additional children (${formik.values.howManyChildren})`}
                price={`£${(
                  coverValues.additionalChildValue * formik.values.howManyChildren
                ).toFixed(2)}`}
              />
            </div>
            </>
          }
          <ButtonsContainer>
            <CustomButton
              handleClick={() => stepStore.previousStep()}
              label="previous step"
              secondary
            />
            <CustomButton handleClick={handleNextStep} label="next step" />
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
});
