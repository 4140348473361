import React from 'react';
import { css } from 'emotion';
import logo from '../../assets/logo.svg';

const containerStyle = css`
  padding: 1.5rem 3rem;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    margin: 0 auto;
    padding: 1rem 3rem 0.5rem 3rem;
  }
`;

const imageStyle = css`
  height: 4rem;
  @media only screen and (max-width: 600px) {
    height: 3rem;
  }
`;

export const Header = () => (
  <div
    className={containerStyle}
    onClick={() =>
      (window.location.href = 'https://quote.yourdentalcover.co.uk/')
    }
  >
    <img src={logo} alt="success" className={imageStyle} />
  </div>
);
